































import {filter} from '@labor-digital/helferlein/lib/Lists/filter';
import BaseAccordion from '@labor-digital/vuetiful/src/Components/BaseAccordion/BaseAccordion.vue';
import Icon from '../Icon/Icon.vue';

export default {
    name: 'Accordion',
    extends: BaseAccordion,
    components: {Icon, BaseAccordion},
    computed: {
        slots()
        {
            // Only pass on slots that we don't overwrite
            return filter(this.$scopedSlots, (v, k) => {
                return k !== 'input-end';
            });
        }
    }
};
