




























import {filter} from '@labor-digital/helferlein/lib/Lists/filter';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../AppStoreKeys';
import Accordion from '../Accordion/Accordion.vue';
import Tabs from '../Tabs/Tabs.vue';

export default {
    name: 'AccordionTabSwitch',
    components: {Tabs, Accordion},
    props: {
        context: ContentElementContext
    },
    computed: {
        slots()
        {
            // Only pass on slots that we don't overwrite
            return filter(this.$scopedSlots, (v, k) => {
                return k !== 'input-end';
            });
        },
        component(): string
        {
            return this.context.store.get(AppStoreKeys.BREAKPOINT).is('<=', 'md') ? 'Accordion' : 'Tabs';
        }
    }
};
