var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c(
        _vm.component,
        {
          tag: "component",
          attrs: {
            items: _vm.tabs,
            "item-label": "label",
            context: _vm.context,
          },
        },
        [
          _vm._l(_vm.tabs, function (tab) {
            return _c(
              "template",
              { slot: tab.label },
              [
                _c("cms-text-link-block", [
                  _c("div", { domProps: { innerHTML: _vm._s(tab.text) } }),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }