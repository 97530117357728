






























import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import Accordion from '../../../Component/Misc/Accordion/Accordion.vue';
import AccordionTab from '../../../Component/Misc/AccordionTabSwitch/AccordionTabSwitch.vue';
import CmsTextLinkBlock from '../../../Component/Misc/Link/CmsTextLinkBlock/CmsTextLinkBlock.vue';

export default {
    name: 'Tabs',
    components: {Accordion, AccordionTab, CmsTextLinkBlock},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        tabs(): Array<PlainObject>
        {
            return this.context.data.get('tabs', []);
        },
        component(): string
        {
            return this.context.data.get('mode', 'tabs') === 'tabs' ? 'accordion-tab' : 'accordion';
        }
    }
};
